import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRect } from '@dx-ui/utilities-use-rect';
import type { TCaptionProps } from '../responsive-image/responsive-image-types';
import ImageControlButton from './image-control-button';
import { Image } from '../image/Image';

export type TImage = {
  id?: string;
  altText: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
  caption?: TCaptionProps;
  headline?: string;
  linkHref?: string;
  linkText?: string;
  text?: string;
};

type TImageStrip = {
  images: TImage[];
  imageWidth?: number;
};

const getDisplayIndex = (activeIndex: number, imagesLength: number) => {
  if ((activeIndex + 1) % imagesLength !== 0) return (activeIndex + 1) % imagesLength;
  else return imagesLength;
};

const getPreviousPreviousIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex - 2 === -1) return imagesLength - 1;
  if (activeIndex - 2 === -2) return imagesLength - 2;
  else return activeIndex - 2;
};

const getPreviousIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex - 1 < 0) return imagesLength - 1;
  else return activeIndex - 1;
};
const getNextIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex + 1 > imagesLength - 1) return 0;
  else return activeIndex + 1;
};
const getNextNextIndex = (activeIndex: number, imagesLength: number) => {
  if (activeIndex + 2 === imagesLength) return 0;
  if (activeIndex + 2 === imagesLength + 1) return 1;
  else return activeIndex + 2;
};

const getOffsets = (imageWidth: number) => {
  const doubleOffset = imageWidth * 2 + 16 * 2;

  return {
    previousPreviousOffset: `-${doubleOffset}px 0px`,
    previousOffset: `-${imageWidth + 16}px 0px`,
    nextOffset: `${imageWidth + 16}px -432px`,
    nextNextOffset: `${doubleOffset}px -432px`,
  };
};

export const ImageStrip = ({ images, imageWidth = 768 }: TImageStrip) => {
  const [t] = useTranslation('dx-image-carousel');
  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const itemText = t('itemLabel') || 'slide';
  const imageCount = (Array.isArray(images) && images.length) || 0;
  const nextText = t('next', {
    itemLabel: itemText,
    count: images.length,
    activeIndexLabel: getDisplayIndex(activeIndex, imageCount),
  });
  const previousText = t('previous', {
    itemLabel: itemText,
    count: imageCount,
    activeIndexLabel: getDisplayIndex(activeIndex, imageCount),
  });

  const selectors =
    'cursor-pointer absolute flex justify-center items-center bg-bg-inverse opacity-80';
  const imgBaseAspectRatio = '16:9';
  const imgBaseClassName = 'object-contain';

  const scroll = (direction: string) => {
    switch (direction) {
      case 'next':
        if (activeIndex === imageCount - 1) {
          setActiveIndex(0);
        } else {
          setActiveIndex(activeIndex + 1);
        }
        break;
      case 'previous':
        if (activeIndex === 0) {
          setActiveIndex(imageCount - 1);
        } else {
          setActiveIndex(activeIndex - 1);
        }
        break;
      default:
        break;
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setTouchStart(e.targetTouches[0].clientX);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const handleTouchEnd = () => {
    // swipe sensitivity can be adjusted - travel distance in [number] pixels of user swipe event
    const SWIPE_SENSITIVITY = 50;
    // swipe left
    if (touchStart - touchEnd > SWIPE_SENSITIVITY && touchEnd > 0) scroll('next');
    // swipe right
    if (touchStart - touchEnd < -SWIPE_SENSITIVITY && touchEnd > 0) scroll('previous');

    setTouchStart(0);
    setTouchEnd(0);
  };

  return (
    <div
      ref={ref}
      className="relative overflow-hidden"
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <div className="my-4 flex justify-center overflow-hidden">
        <ImageControlButton
          icon="previous"
          className={`${selectors} left-0 top-1/2 z-20 h-12 w-8 text-2xl`}
          labelText={previousText}
          navigationText={t('previousNavText')}
          onClick={() => scroll('previous')}
        />
        <div className="z-10">
          {(rect?.width ?? 0) >= 2364 ? (
            <div
              className="absolute"
              style={{
                width: imageWidth,
                translate: getOffsets(imageWidth).previousPreviousOffset,
              }}
              role="presentation"
            >
              {images.map((image, index) => (
                <div
                  key={image.altText}
                  className={cx('snap-center rounded', {
                    hidden: index !== getPreviousPreviousIndex(activeIndex, images.length),
                  })}
                >
                  <Image
                    alt={image.altText}
                    src={image.desktopImageUrl}
                    width={imageWidth}
                    aspectRatio={imgBaseAspectRatio}
                    className={imgBaseClassName}
                  />
                </div>
              ))}
            </div>
          ) : null}

          <div
            className="absolute"
            style={{
              width: imageWidth,
              translate: getOffsets(imageWidth).previousOffset,
            }}
            role="presentation"
          >
            {images.map((image, index) => (
              <div
                key={image.altText}
                className={cx('snap-center rounded', {
                  hidden: index !== getPreviousIndex(activeIndex, images.length),
                })}
              >
                <Image
                  alt={image.altText}
                  src={image.desktopImageUrl}
                  width={imageWidth}
                  aspectRatio={imgBaseAspectRatio}
                  className={imgBaseClassName}
                />
              </div>
            ))}
          </div>
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <div className={`w-[${imageWidth}px]`}>
            {images.map((image, index) => (
              <div
                key={image.altText}
                className={cx('snap-center rounded', {
                  hidden: index !== activeIndex,
                })}
              >
                <Image
                  alt={image.altText}
                  src={image.desktopImageUrl}
                  width={imageWidth}
                  aspectRatio={imgBaseAspectRatio}
                  className={imgBaseClassName}
                />
              </div>
            ))}
          </div>
          <div
            className="absolute"
            style={{
              width: imageWidth,
              translate: getOffsets(imageWidth).nextOffset,
            }}
            role="presentation"
          >
            {images.map((image, index) => (
              <div
                key={image.altText}
                className={cx('snap-center rounded', {
                  hidden: index !== getNextIndex(activeIndex, images.length),
                })}
              >
                <Image
                  alt={image.altText}
                  src={image.desktopImageUrl}
                  width={imageWidth}
                  aspectRatio={imgBaseAspectRatio}
                  className={imgBaseClassName}
                />
              </div>
            ))}
          </div>
          {(rect?.width ?? 0) >= 2364 ? (
            <div
              className="absolute"
              style={{
                width: imageWidth,
                translate: getOffsets(imageWidth).nextNextOffset,
              }}
              role="presentation"
            >
              {images.map((image, index) => (
                <div
                  key={image.altText}
                  className={cx('snap-center rounded', {
                    hidden: index !== getNextNextIndex(activeIndex, images.length),
                  })}
                >
                  <Image
                    alt={image.altText}
                    src={image.desktopImageUrl}
                    width={imageWidth}
                    aspectRatio={imgBaseAspectRatio}
                    className={imgBaseClassName}
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>
        <ImageControlButton
          icon="next"
          className={`${selectors} right-0 top-1/2 z-20 h-12 w-8 text-2xl`}
          labelText={nextText}
          navigationText={t('nextNavText')}
          onClick={() => scroll('next')}
        />
        <div
          className={`${selectors} text-bg-alt bottom-4 right-0 z-20 h-8 w-12 px-8 py-4 text-sm`}
        >
          {getDisplayIndex(activeIndex, images.length)}/{images.length}
        </div>
      </div>
    </div>
  );
};
